import { Button, Card, Form, notification } from "antd";
import FormInput from "shared/components/FormInput/FormInput";
import RadioButton from "shared/components/RadioButton/RadioButton";
import { signUpStages } from "./sign-up";
import { getEnumList } from "shared/services/Utility";
import "../authentication.scss";
import { useState } from "react";
import API_SERVICE from "shared/services/api-service";

interface ISignUpBusinessDetails {
  setSelectedSignUpStage: React.Dispatch<React.SetStateAction<signUpStages>>;
  saveUserData: Function;
  userDetails: any;
}

const SignUpBusinessDetails: React.FunctionComponent<ISignUpBusinessDetails> = (
  props: ISignUpBusinessDetails
) => {
  const { setSelectedSignUpStage, saveUserData, userDetails } = props;

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values: any) => {
    
    const params = {
      businessName: values.businessName,
      businessType: values.businessType,
    };
    
    console.log("params: ", params);
    
    ///////////////////////////////
    // saveUserData(data);
    // setSelectedSignUpStage("authSignatory");
    // return;
    ////////////////////////////////

    console.log("UserData: ", userDetails);

    setLoading(true);
    API_SERVICE.updateBusiness(userDetails?.userId, params)
      .then(({ data }) => {
        setLoading(false);
        if (data) {
          notification.success({ message: data.message });
          saveUserData(params);
          setSelectedSignUpStage("authSignatory");
        }
      })
      .catch(function (e) {
        notification.error({ message: API_SERVICE.handleErrors(e) });
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderForm = () => {
    return (
      <Form
        style={{ marginBottom: "70px", marginTop: "10px" }}
        size="large"
        layout="vertical"
        onFinish={handleSubmit}
      >
        <RadioButton
          label="Business Type"
          name="businessType"
          radioList={getEnumList("BUSINESS_TYPE")}
          className="custom-radio profession-btns"
          isRequired={true}
          requiredLabel="business type"
        />

        <FormInput
          classNameForm="sign-up-input"
          label="Business Name"
          name="businessName"
          placeholder="Enter Business Name"
          requiredLabel="business name"
          isBorder
          isRequired
          rules={[
            {
              message: "Please enter valid otp",
            },
          ]}
        />
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            size={"large"}
            className="oneflo-full-btn"
            loading={loading}
          >
            Continue
          </Button>
        </Form.Item>
      </Form>
    );
  };

  return (
    <Card className="authentication-box">
      <h3 className="welcome-to-oneflo">
          Welcome to
          <span className="oneflo">{window.location.origin.includes("loantalk") ? "LOANTALK" : "ONEFLO"}</span>
        </h3>
      <h3 className="authentication-header" style={{ marginTop: "10px" }}>
        Enter Your Business Details
      </h3>
      <div className="authentication-form">{renderForm()}</div>
    </Card>
  );
};

export default SignUpBusinessDetails;
