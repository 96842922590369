import { useEffect, useState } from "react";
import { Button, Card, Form, Space, notification } from "antd";
import { Link } from "react-router-dom";
import FormInput from "shared/components/FormInput/FormInput";
import { signUpStages } from "./sign-up";
import "../authentication.scss";
import API_SERVICE from "shared/services/api-service";
import RadioButton from "shared/components/RadioButton/RadioButton";

interface ISignUpReferralCode {
  setSelectedSignUpStage: React.Dispatch<React.SetStateAction<signUpStages>>;
  saveUserData: Function;
}

const SignUpReferralCode: React.FunctionComponent<ISignUpReferralCode> = (
  props: ISignUpReferralCode
) => {
  const { setSelectedSignUpStage, saveUserData } = props;

  const [loading, setLoading] = useState(false);
  const [formSignIn] = Form.useForm();
  const [referrallValue, setReferrallValue] = useState("");

  useEffect(() => {
    formSignIn.setFieldsValue({
      doYouHaveReferralCode: referrallCheck[0]?.key,
    });
  }, []);

  const onSumbitRefferalCode = (values) => {
    const referralCode = values?.referralCode;
    if (referralCode) {
      API_SERVICE.checkReferralCode(referralCode)
        .then(({ data }) => {
          saveUserData({
            dsaBusinessId: data?.payload?.dsaBusinessId,
            referralId: data?.payload?.connector ? values?.referralCode : "",
            managerId: data?.payload?.employee ? values?.referralCode : "",
          });
          setSelectedSignUpStage("selectType");
        })
        .catch((e: any) => {
          notification.error({ message: API_SERVICE.handleErrors(e) });
        });
    } else if (values?.appropriateOption === "IDON'THAVE") {
      setSelectedSignUpStage("selectType");
    } else {
      notification.error({
        message: "Contact your Distribution Channel for the referral code",
      });
    }
  };

  const referrallCheck = [
    {
      key: "Y",
      value: "Yes",
    },
    {
      key: "N",
      value: "No",
    },
  ];

  const appropriateList = [
    {
      key: "IFORGOT",
      value: "I forgot",
    },
    {
      key: "IDON'THAVE",
      value: "I don't have",
    },
  ];

  const renderForm = () => {
    return (
      <Form
        size="large"
        form={formSignIn}
        onFinish={(values: string) => {
          onSumbitRefferalCode(values);
        }}
        layout="vertical"
      >
        <RadioButton
          label="Do you have Referral Code?"
          name="doYouHaveReferralCode"
          radioList={referrallCheck}
          defaultValue={referrallCheck[0]?.key}
          onChange={(e: any) => setReferrallValue(e?.target?.value)}
          className="custom-radio profession-btns"
          isRequired
        />
        {referrallValue === "N" ? (
          <>
            <RadioButton
              label="Please select the appropriate option"
              name="appropriateOption"
              radioList={appropriateList}
              defaultValue={appropriateList[0].key}
              className="custom-radio profession-btns"
              isRequired
            />
          </>
        ) : (
          <>
            <FormInput
              name="referralCode"
              classNameForm="oneflo-bottom-space"
              label="Please Enter Referral Code"
              placeholder="Enter your Referral Code"
              requiredLabel="referral Code"
              isBorder
              isRequired
            />
          </>
        )}

        <Form.Item className="oneflo">
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            size={"large"}
            className="oneflo-full-btn"
          >
            Continue
          </Button>
        </Form.Item>

        <Form.Item>
          <p className="already-a-user text-center">
            Already a User?{" "}
            <Link to="/sign-in" className="sign-in-link">
              Sign In
            </Link>
          </p>
        </Form.Item>
      </Form>
    );
  };

  return (
    <Card className="authentication-box">
      <h3 className="welcome-to-oneflo">
        Welcome to
        <span className="oneflo">{window.location.origin.includes("loantalk") ? "LOANTALK" : "ONEFLO"}</span>
      </h3>
      <h3 className="authentication-header-small-no-bottom-space">
        Referral Details
      </h3>
      <br />
      <br />
      <div className="authentication-form">{renderForm()}</div>
    </Card>
  );
};

export default SignUpReferralCode;
