import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row, Tabs, notification } from "antd";
import Decisioning from "./Decisioning";
import Login from "./Login/Login";
import API_SERVICE from "shared/services/api-service";
import {ReactComponent as ArrowLeft} from "../../../assets/images/arrow_left.svg";
import ProductSteps from "./ProductSteps";
import ProductFooter from "./ProductFooter";
import Document from "./Document/Document";
import BankStatement from "./BankStatement";
import ListLeadDetails from "./LenderDisbursed/ListLeadDetails";
import LenderDecision from "./LenderDecision/LenderDecision";
import GetBureau from "screens/Lead/GetBureau";
import AppLoading from "shared/components/AppLoading/AppLoading";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
import LoginTabs from "./Login/LoginTabs";
import LenderPrivacyPolicy from "./LenderPrivacyPolicy/LenderPrivacyPolicy";

interface ILeadDetailNewTabs {
  leadData: any;
  selectedLenderList: any;
  lenderDisbursedHandler: any;
  getRedirectURLLink: any;
  getDownloadLetterForICICI: any;
  loadingUpdate: any;
  getCashETriggerSMSLink: any;
  updateCase: any;
  getJSONSchema: any;
  callbackReqDoc: any;
  callbackAdditionalField: any;
  setShowRequirementDocs: any;
  setSelectedLander: any;
  setAdditionalFields: any;
  setCurrLenderId: any;
  setCurrLeadId: any;
  setShowAdditionalFields: any;
  // getBureauScore: any;
  genrateBureauScore: any;
  bureauColor: any;
  consetObj: any;
  bureauScoreStatus: any;
  setShowApplicantInfo: any;
  isApplicantModal: any;
  setIsApplicantModal: any;
  lenderDecisionHandler: any;
  setIsGetBureauNext: any;
  isGetBureauNext: any;
  getLeadById: any;
  goToDocument: any;
  setGotoDocument: any;
  setLoading:any
 // isConnectorEditForm:boolean;
}

const LeadDetailNewTabs: React.FC<ILeadDetailNewTabs> = (
  props: ILeadDetailNewTabs
) => {
  const {
    leadData,
    selectedLenderList,
    lenderDisbursedHandler,
    getRedirectURLLink,
    getDownloadLetterForICICI,
    loadingUpdate,
    getCashETriggerSMSLink,
    updateCase,
    getJSONSchema,
    callbackReqDoc,
    callbackAdditionalField,
    setShowRequirementDocs,
    setSelectedLander,
    setAdditionalFields,
    setCurrLenderId,
    setCurrLeadId,
    setShowAdditionalFields,
    bureauColor,
    bureauScoreStatus,
    consetObj,
    genrateBureauScore,
    // getBureauScore,
    setShowApplicantInfo,
    isApplicantModal,
    setIsApplicantModal,
    lenderDecisionHandler,
    isGetBureauNext,
    setIsGetBureauNext,
    getLeadById,
    goToDocument,
    setGotoDocument,
    setLoading, 
   // isConnectorEditForm
  } = props;
  const [currentStage, setCurrentStage] = useState("");
  const [currentSteps, setCurrentSteps] = useState("");
  const [isProductFooter, setIsProductFooter] = useState(false);
  const [getTaskData, setGetTaskData] = useState({} as any);
  const [productWorkflowConfig, setProductWorkflowConfig] = useState({} as any);
  const [disposition, setDisposition] = useState([] as any);
  const [dispositionList, setDispositionList] = useState([] as any);
  const [selectedStageDetail, setSelectedStageDetail] = useState({} as any);
  const [isGetBureau, setIsGetBureau] = useState(false);
  const [isFinBox, setIsFinBox] = useState(false);
  const [showDecisioning, setShowDecisioning] = useState(false);
  const [isShowDisbursed, setIsShowDisbursed] = useState(false);
  const [isStepReadOnly, setIsStepReadOnly] = useState(false);
  const [showConflict, setShowConflict] = useState(false);
  const [conflictMessage, setConflictMessage] = useState("");
  const { appColors }: any = useSelector((state: RootState) => state.common);
  const [isConnectorEditform, setIsConnectorEditForm] = useState(false);
  const { tenantInfo }: any = useSelector((state: RootState) => state.tenant);
  
  useEffect(() => {
    fetchProductWorkflowById(leadData?.masterProductId);
  }, []);

  useEffect(() => {
    if (isGetBureauNext && isGetBureau) {
      submitTask({ taskId: getTaskData?.taskId });
    }

    setIsGetBureauNext(false);
  }, [isGetBureauNext]);

  const fetchProductWorkflowById = (id: string) => {
    setLoading(true);
    API_SERVICE.fetchProductWorkflowById(id)
      .then(({ data }) => {
        setProductWorkflowConfig(data?.payload?.productWorkflowConfig ?? {});
        setDisposition(data?.payload?.dispositions ?? []);
        const firstStage =
          data?.payload?.productWorkflowConfig?.stages?.at(0) ?? [];
        stageOnChange(
          firstStage?.key,
          data?.payload?.productWorkflowConfig,
          data?.payload?.dispositions
        );
        getNextTask(
          {
            processInstanceId: leadData?.processInstanceId,
            clientType: "WEB",
          },
          null, null, false
        );
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE?.handleErrors(e) });
        setLoading(false)
      })
      
      // .finally(()=>setLoading(false));
  };

  const connectorEditFormStatus =(type:string, payload?:any)=>{
    const caseId = leadData?.caseDetails?.caseId;
    API_SERVICE.getConnectorEditStatus(caseId)
    .then(({ data }) => {
      if (data?.payload) {
        setIsConnectorEditForm(data?.payload?.canConnectorEditForm)
      }
      if (type == 'submit')
      {
     
      if (!data?.payload?.canConnectorEditForm) {
        setShowConflict(true);
        setConflictMessage(data?.payload?.message);
      } 
      else{
        submitTaskAPI(payload);
      }
    }
   })
   .catch((e: any) => {
   
      notification.error({ message: API_SERVICE?.handleErrors(e) });
      setShowConflict(false)
      ;
    }
    //  setIsLoading(false);
  )
  }

  const getNextTask = (
    payload: any,
    productWorkflow: any = productWorkflowConfig,
    dispositionData: any = disposition,
    isCallingLeadDetail: boolean = true,
  ) => {
    // const payload = {
    //   processInstanceId: id,
    //   clientType: "WEB",
    // };
    //  setLoading(true);
    API_SERVICE.productGetNextTask(payload)
      .then(({ data }) => {
        setShowConflict(false);
        setCurrentStage(data?.payload?.productFormMetadata?.stageKey);
        if(isCallingLeadDetail) {
          getLeadById();
        }
        connectorEditFormStatus('gettask');
        // stageOnChange(
        //   data?.payload?.productFormMetadata?.stageKey,
        //   productWorkflow,
        //   dispositionData,
        //   data?.payload ?? {}
        // );
        stepOnChange(
          data?.payload?.productFormMetadata?.stepKey,
          data?.payload ?? {}
        );
        setCurrentSteps(data?.payload?.productFormMetadata?.stepKey);
        setGetTaskData(data?.payload ?? {});
        setIsGetBureau(
          data?.payload?.productFormMetadata?.actionId == "GET_BUREAU"
        );
        setIsFinBox(
          data?.payload?.productFormMetadata?.actionId ==
            "BANK_STATEMENT_ANALYSIS"
        );
        setShowDecisioning(
          data?.payload?.taskDefinitionKey == "decisioning_output_modal"
        );
        setIsShowDisbursed(false);
      })
      .catch((e: any) => {
        if (e?.response?.status == 409) {
          setShowConflict(true);
          setConflictMessage(e?.response?.data?.payload?.errorMessage);
        } else {
          notification.error({ message: API_SERVICE?.handleErrors(e) });
          setShowConflict(false);
        }
      })
      .finally(()=>setLoading(false));
    };
    const submitTask = (payload: any) => {
      connectorEditFormStatus('submit', payload)
    }

  const submitTaskAPI = (payload: any) => {
    setLoading(true)
    API_SERVICE.productSubmitTask(payload)
      .then(() => {
        setShowDecisioning(false);
       
        getNextTask({
          processInstanceId: leadData?.processInstanceId,
          clientType: "WEB",
        });
                // getNextTask(leadData?.processInstanceId);
      })
      .catch((e: any) => {
        if (e?.response?.status == 409) {
          setShowConflict(true);
          setConflictMessage(e?.response?.data?.payload?.errorMessage);
        } 
        
        else {
          notification.error({ message: API_SERVICE?.handleErrors(e) });
          setShowConflict(false)
          ;
        }
        setLoading(false);
      })
    //  .finally(()=>setLoading(false));;;
  };

  const stageOnChange = (key: any, data: any, disposition, tmpData: any = getTaskData) => {
    setGotoDocument("");
    setProductWorkflowConfig(data);
    setCurrentStage(key);
    const selectedStageTmp = data?.stages?.find((item: any) => {
      return item?.key == key;
    });
    setSelectedStageDetail(selectedStageTmp);
    const displayDispositionList = disposition?.find((item: any) => {
      return item?.stageKey == key;
    });
    setDispositionList(displayDispositionList?.disposition1And2 ?? []);
    if ((selectedStageTmp?.steps?.length ?? 0) > 0) {
      for (let index = 0; index < selectedStageTmp?.steps?.length; index++) {
        const stepkey = selectedStageTmp?.steps[index];
        if ((!stepkey?.journeyBuilderConfiguration?.disableConfiguration) && (stepkey?.runType?.isEnabled != "FALSE")) {
          setCurrentSteps(stepkey?.key);
          connectorEditFormStatus('gettask');
          setIsStepReadOnly(
            stepkey?.key != tmpData?.productFormMetadata?.stepKey
          );
          break;
        }
      }
    }
  };


  const stepOnChange = (key: any, tmpData: any = getTaskData) => {
    setCurrentSteps(key);
    setGetTaskData(tmpData)
    setIsStepReadOnly(
      key !== tmpData?.productFormMetadata?.stepKey ||
      tmpData?.taskDefinitionKey == "vas_user_task"
    );
  };

  const renderSteps = (item: any) => {
    const tabStyle: any = {'--borderColor': appColors?.appPrimaryColor ?? ""};
    return (
      <>
        <Tabs
          onChange={(key) => stepOnChange(key)}
          activeKey={currentSteps}
          className="lender-sub-tabs"
          style={tabStyle}
        >
          {item?.steps
          ?.filter((item: any) => item?.runType?.isEnabled != "FALSE")
          ?.map((step: any) => {
            return (
              <Tabs.TabPane
                key={step?.key}
                tab={step?.name}
                style={{ paddingInline: "10px" }}
              >
                <ProductSteps
                 getTaskData={getTaskData}
                 submitTask={submitTask}
                 leadData={leadData}
                 getJSONSchema={getJSONSchema}
                 formJsonSchema={JSON.stringify(step?.form?.definition)}
                 isStepReadOnly={isStepReadOnly}
                 currentSteps={currentSteps}
                 getNextTask={getNextTask}
                 isConnectorEditform={isConnectorEditform}
                 setGetTaskData={setGetTaskData}
                />
              </Tabs.TabPane>
            );
          })}
        </Tabs>
      </>
    );
  };

  const renderDecisioningOutput = () => (
    <Modal
      title="Decisioning Output"
      width={700}
      visible={showDecisioning}
      okText="OK"
      closable={false}
      maskClosable={false}
      footer={false}
    >
      <div className="">
        <ListLeadDetails
          leadData={leadData}
          showDecisioning={showDecisioning}
          setShowDecisioning={setShowDecisioning}
        />
      </div>
    </Modal>
  );

  const renderConfictMessage = () => (
    <Modal
      centered
      width={400}
      // onCancel={() => setIsFilterVisible(false)}
      closable={false}
      footer={null}
      visible={showConflict}
    >
      <div style={{ padding: "15px", textAlign: "center" }}>
        <p style={{ fontSize: "18px" }}>{conflictMessage}</p>
        <Button
          type="primary"
          onClick={() =>
            getNextTask({
              processInstanceId: leadData?.processInstanceId,
              clientType: "WEB",
            })
          }
          style={{
            width: "120px",
          }}
        >
          OK
        </Button>
      </div>
    </Modal>
  );

  return (
    <>
      <div style={{ display: "flex" }}>
        <Row style={{ width: "100%" }}>
          <Col span={24}>
            <Tabs
              className="lender-main-tabs"
              activeKey={goToDocument?.length > 0 ? goToDocument : currentStage}
              onChange={(key) =>
                stageOnChange(key, productWorkflowConfig, disposition)
              }
              tabBarExtraContent={
                currentStage == "lenderDecisionStage" && isShowDisbursed ? (
                  <Button
                    onClick={() => setIsShowDisbursed(false)}
                    className="back-btn"
                    icon={<ArrowLeft height={15}/>}
                    style={{'--btnColor': appColors?.appPrimaryColor ?? ""} as any}
                  >
                    Back
                  </Button>
                ) : (
                  ""
                )
              }
              //activeKey={currentStage}
              style={{ "--color": appColors?.appPrimaryColor ?? "" } as any}
            >
              {productWorkflowConfig?.stages
              ?.filter((item: any) => item?.runType?.isEnabled != "FALSE")
              ?.map((tab: any) => {
                return (
                  <Tabs.TabPane
                    key={tab?.key}
                    tab={tab?.name}
                    // disabled={
                    //   tab?.key !== "documentStage" &&
                    //   tab?.key !== "prospectStage" &&
                    //   tab?.key !== getTaskData?.productFormMetadata?.stageKey
                    // }
                  >
                    {(tab?.steps?.length ?? 0) > 0 ? (
                      renderSteps(tab)
                    ) : tab?.journeyBuilderConfiguration?.showDocumentList ? (
                      <Document
                        setIsLoading = {setLoading}
                        leadData={leadData}
                        getTaskData={getTaskData}
                        setCurrentStage={setCurrentStage}
                        setGotoDocument={setGotoDocument}
                      />
                    ) : tab?.key == "decisioningStage" ? (
                      <Decisioning
                      setIsLoading = {setLoading}
                        leadData={leadData}
                        getTaskData={getTaskData}
                      />
                    ) : tab?.key == "loginStage" ? (
                      <LoginTabs
                        setIsLoading = {setLoading}
                        leadData={leadData}
                        updateCase={updateCase}
                        getRedirectURLLink={getRedirectURLLink}
                        getCashETriggerSMSLink={getCashETriggerSMSLink}
                        callbackReqDoc={callbackReqDoc}
                        callbackAdditionalField={callbackAdditionalField}
                        setShowRequirementDocs={setShowRequirementDocs}
                        setSelectedLander={setSelectedLander}
                        setAdditionalFields={setAdditionalFields}
                        setCurrLenderId={setCurrLenderId}
                        setCurrLeadId={setCurrLeadId}
                        setShowAdditionalFields={setShowAdditionalFields}
                      />
                    ) : tab?.key == "lenderDecisionStage" ?
                        tenantInfo?.businessProductType === "INSURANCE" 
                        ? <LenderPrivacyPolicy leadData={leadData}/> 
                        : (
                      <LenderDecision
                        leadData={leadData}
                        selectedLenderList={selectedLenderList}
                        lenderDisbursedHandler={lenderDisbursedHandler}
                        lenderDecisionHandler={lenderDecisionHandler}
                        getRedirectURLLink={getRedirectURLLink}
                        getDownloadLetterForICICI={getDownloadLetterForICICI}
                        loadingUpdate={loadingUpdate}
                        getCashETriggerSMSLink={getCashETriggerSMSLink}
                        isShowDisbursed={isShowDisbursed}
                        setIsShowDisbursed={setIsShowDisbursed}
                        setIsLoading = {setLoading}
                      />
                      // <LenderPrivacyPolicy  leadData={leadData}/>
                    ) : (
                      ""
                    )}
                  </Tabs.TabPane>
                );
              })}
            </Tabs>
          </Col>
          <Col span={24} className="border-top-3" >
            {isProductFooter ? (
              <div style={{ display: "flex", padding: "10px" }}>
                <ProductFooter
                  dispositionList={dispositionList}
                  setIsProductFooter={setIsProductFooter}
                  caseId={leadData?.caseDetails?.caseId}
                  selectedStageDetail={selectedStageDetail}
                  caseStatus={leadData?.caseDetails?.caseStatus}
                  getLeadById={getLeadById}
                />
              </div>
            ) : (
              <div
                className="add-comment"
                onClick={() => setIsProductFooter(true)}
              >
                <p style={{ padding: "10px" }}>Add Comment here...</p>
              </div>
            )}
          </Col>
        </Row>
      </div>
      {isFinBox && (
        <BankStatement
          setIsFinBox={setIsFinBox}
          leadData={leadData}
          submitTask={submitTask}
          getTaskData={getTaskData}
        />
      )}
      {showDecisioning && renderDecisioningOutput()}
      {showConflict && renderConfictMessage()}
    </>
  );
};
export default LeadDetailNewTabs;
