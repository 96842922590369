import { useState } from "react";
import { Button, Card, Form, notification, Spin } from "antd";
import { Link, Redirect } from "react-router-dom";
import { AuthConsumer } from "shared/contexts/AuthContext";
import API_SERVICE from "shared/services/api-service";
import Authentication from "./authentication";
import FormInput from "shared/components/FormInput/FormInput";
import FormInputPassword from "shared/components/FormInput/FormInputPassword";
import { PHONE_NUMBER_REGEX } from "shared/constants/AppConstants";
import "./authentication.scss";
import ReCAPTCHA from 'react-google-recaptcha';
import { getUserDetails } from "shared/services/Utility";

const SignIn = () => {
  const [fields, setFields] = useState([] as any[]);
  const [loading, setLoading] = useState(false);
  const [showRecaptcha, setShowRecaptcha] = useState(false)
  const { from } = { from: { pathname: "/dashboard" } };
  const [formSignIn] = Form.useForm();
  const [reCaptchaToken, setReCaptchaToken] = useState("") as any
  const getAllTypes = async () => {
    API_SERVICE.getTypes().then(({ data }) => {
      if (data) {
        localStorage.setItem("enumValues", JSON.stringify(data));
      }
    });
  };

  const getUsersPermissions = async () => {
    const { data } = await API_SERVICE.getUsersPermission(
      getUserDetails()?.userId
    );
    if (data) {
      localStorage.setItem(
        "userPermission",
        JSON.stringify(data.payload.roles)
      );
      window.location.reload();
    }
  };

  const getConnector = async () => {
    const { data } = await API_SERVICE.getConnectorById(
      getUserDetails()?.userId
    );
    localStorage.setItem(
      "basicDetails",
      JSON.stringify(data?.payload?.basicDetails)
    );
  };

  async function handleCaptcha(e:any){
    setLoading(true)
    if(e.target.value.length === 10){
      try {    
      const payload = {
        mobileNumber:e.target.value,
        password:"",
        reCaptchaScreenName:"SIGN_IN"
      }
      const { data } = await API_SERVICE.checkCaptchaRequired(payload);
        if(data)
        {
          setShowRecaptcha(data?.payload?.captchaRequired)
        }
     }
  catch (e) {
    setShowRecaptcha(false)
    setLoading(false)
    notification.error({
      message: API_SERVICE.handleErrors(e),
        
    });
    } 
  }
  setLoading(false)
}


async function handleSignInCaptcha(payload)
{
  try { 
    const { data } = await API_SERVICE.checkCaptchaRequired(payload);
      if(data)
      {
        setShowRecaptcha(data?.payload?.captchaRequired)
      }
      }
  
catch (e) {
   notification.error({
   message: API_SERVICE.handleErrors(e),
  });
  }  
}
async function handleSubmit(values: any, updateAPICreds: any) {
    const { mobileNumber, password } = values;
    const payload = {
      mobileNumber:values?.mobileNumber,
      password:values?.password,
      reCaptchaScreenName:"SIGN_IN"
    }
    handleSignInCaptcha(payload)
    if((reCaptchaToken.length === 0 && showRecaptcha)){
      notification.error({
        message: "reCaptcha is not checked",
      });
    }
    else{
    setLoading(true);
    setReCaptchaToken("")
    setShowRecaptcha(false)
    try {
      const { data } = await API_SERVICE.login({
        mobileNumber,
        password,
      });
     
      if (!data) {
        throw "login.unableToLogin";
              }
      if (data?.payload?.userDetails?.role !== "ROLE_CONNECTOR") {
        throw `User with: ${mobileNumber} not allowed/permitted to login`;
      }

      let redirectURL = data?.payload?.tenantDocumentDetails?.tenantRedirectionUrl ?? "";
      // if(redirectURL.length > 0) {
      //   // redirectURL = "https://tenant-d3dcweb.oneflotest.in/login";
      //   redirectURL = "";
      // }
      const dcConnectorURLs = [
        "https://uat-connector.oneflotest.in", 
        "https://dev-connector.oneflotest.in", 
        "https://dev1-connector.oneflotest.in", 
        "https://dev2-connector.oneflotest.in", 
        "https://dev3-connector.oneflotest.in", 
        "https://d4-connector.oneflotest.in", 
        "https://demo-connector.oneflotest.in",
        "https://pp-cntr.oneflotest.in", 
        "https://connector.oneflo.in",
        "http://localhost:3012"
      ]
      console.log("redirectURL: ", redirectURL)
      console.log("window.location.origin: ", window.location.origin)
      if(redirectURL.length > 0 && redirectURL != window.location.origin) {
        if(dcConnectorURLs.includes(window.location.origin)) {
          notification.error({message: `User with: ${mobileNumber} not allowed/permitted to login`})
          setTimeout(() => {
            window.location.replace(redirectURL);
          }, 2000);
        } else {
          throw `User with: ${mobileNumber} not allowed/permitted to login`;
        }
      } else if(dcConnectorURLs.includes(window.location.origin) || 
                redirectURL == window.location.origin) {
        localStorage.setItem("user", JSON.stringify(data?.payload));
        await getConnector();
        await getUsersPermissions();
        updateAPICreds(data);
        getAllTypes();
      } else {
        throw `User with: ${mobileNumber} not allowed/permitted to login`;
      }
    } catch (e) {
        notification.error({
        message: API_SERVICE.handleErrors(e),
      });
      setLoading(false);
    }  
  }
  }

  const renderForm = (updateAPICreds: any) => {
    return (
      <Form
        size="large"
        layout="vertical"
        form={formSignIn}
        fields={fields}
        onFieldsChange={(changedFields, allFields) => {
          setFields(allFields);
        }}
        onFinish={(values: any) => {
          handleSubmit(values, updateAPICreds);
        }}
      >
        <FormInput
          name={"mobileNumber"}
          classNameForm="oneflo-bottom-space"
          prefix="+91"
          label="Enter Your Mobile Number"
          isBorder
          isRequired={true}
          requiredLabel="Mobile Number"
          placeholder="Mobile number"
          onChange={handleCaptcha}
          rules={[
            {
              pattern: PHONE_NUMBER_REGEX,
              message: "Please enter valid number",
            },
          ]}
        />

        <FormInputPassword
          name={"password"}
          label="Enter Your Password"
          classNameForm="oneflo-bottom-space"
          isBorder
          isRequired={true}
          requiredLabel="Password"
          placeholder="Password"
        />

        <Form.Item className="oneflo oneflo-bottom-space text-right">
          <Link to="/forgot-password" className="forgot-password-link">
            Forgot Password
          </Link>
        </Form.Item>
        { showRecaptcha &&
        <div className="g-recaptcha">
          <ReCAPTCHA
            sitekey="6LctCysqAAAAAFuSCNIHih9gX7V6yZY6SL4NsFWY" // Replace with your site key
            onChange={(token)=>setReCaptchaToken(token)}
          />
          
          </div>
        }
        <Form.Item className="oneflo oneflo-bottom-space">
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            size={"large"}
            className="oneflo-full-btn"
          >
            Sign in
          </Button>
        </Form.Item>
        <Form.Item>
          <p className="no-account text-center">
            No Account?{" "}
            <Link to="/sign-up" className="sign-up-link">
              Sign Up
            </Link>
          </p>
        </Form.Item>
      </Form>
    );
  };

  const renderView = (updateAPICreds: any) => {
    return (
      <Card className="authentication-box">
        <h3 className="welcome-to-oneflo">
          Welcome to<span className="oneflo">{window.location.origin.includes("loantalk") ? "LOANTALK" : "ONEFLO"}</span>
        </h3>
        <h3 className="authentication-header">Sign in</h3>
        <div className="authentication-form">{renderForm(updateAPICreds)}</div>
      </Card>
    );
  };

  return (
    <AuthConsumer>
      {({ isAuth, updateAPICreds, permissions }) => {
        return isAuth ? (
          <Redirect to={from} />
        ) : (
          <Authentication title="Sign in to">
            {renderView(updateAPICreds)}
          </Authentication>
        );
      }}
    </AuthConsumer>
  );
};

export default SignIn;
