import { Col, Row } from "antd";
import oneflow from "assets/images/oneflo.svg";
import oneflowFlyingPlane from "assets/images/oneflo-flying-plane.svg";
import brainImg from "assets/images/brainimage.svg";
import crystal1 from "assets/images/crystal1.svg";
import ring from "assets/images/ring.svg";
import coin1 from "assets/images/coin1.svg";
import coins2 from "assets/images/coins2.svg";
import innovation1 from "assets/images/innovation1.svg";
import setting1 from "assets/images/setting1.svg";
import moneysign from "assets/images/moneysign.svg";
import hierarchy1 from "assets/images/hierarchy1.svg";
import crystal3 from "assets/images/crystal3.svg";
import crystal2 from "assets/images/crystal2.svg";
import backgroundImage from "assets/images/backgroundimage.svg";
import "./authentication.scss";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

interface IAuthentication {
  title: string;
  children: JSX.Element;
}

const Authentication: React.FunctionComponent<IAuthentication> = (
  props: IAuthentication
) => {
  const { title, children } = props;
  const isMobile: boolean = useSelector((state: RootState) => state.common.isMobile);

  return (
      <div className="imageView">
        <img className="crystal1" src={crystal1} />
        <img className="ring" src={ring} />
        <img className="coin1" src={coin1} />
        <img className="coins2" src={coins2} />
        <img className="innovation1" src={innovation1} />
        <img className="setting1" src={setting1} />
        <img className="moneysign" src={moneysign} />
        <img className="hierarchy1" src={hierarchy1} />
        <img className="crystal3" src={crystal3} />
        <img className="crystal2" src={crystal2} />
        
        <div className="authentication-main-view">
          
          {!isMobile &&
            <Row className="brainRow" style={{alignItems:"center"}}>

            <Col xs={0} sm={0} md={0} lg={2} className="flying-plane text-center">
              </Col>

              <Col xs={24} sm={24} md={24} lg={8} className="header-logo-details">
                <h3 className="page-header">HOP IN TO EXPLORE THE MOST
                INTELLIGENT INTEGRATED CRM</h3>
                <img className="logo-details" src={brainImg} width={120} />
                {/* <p className="header-content">
                  Lorem Ipsum is simply dummy text of the printing and typesetting
                  industry. Lorem Ipsum has been the industry's standard dummy text
                  ever since the 1500s,
                </p> */}
              </Col>
              <Col xs={0} sm={0} md={0} lg={3} className="flying-plane text-center">
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={9}
                className="authentication-container"
              >
                {children}
              </Col>
            </Row>}
          {isMobile &&
            <Row>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={9}
                className="authentication-container"
              >
                {children}
              </Col>
              
              <Col xs={24} sm={24} md={24} lg={6} className="header-logo-details" style={{justifyContent: "center" }}>
              <h3 className="page-header">HOP IN TO EXPLORE THE MOST
                INTELLIGENT INTEGRATED CRM</h3>
                <img className="logo-details" src={brainImg} width={120} />
              </Col>
            </Row>}
        </div>
      </div>
  );
};
export default Authentication;
